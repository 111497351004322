<!-- eslint-disable -->
<template>
  <div style="border: 1px solid #ccc">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      style="height: 400px; overflow-y: hidden"
      v-model="html"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onChange="onChange"
      @onCreated="onCreated"
    />
  </div>
</template>
<script>
/* eslint-disable */
import Vue from "vue";
import axios from "axios";
// import { getToken } from "@/utils/auth";
import userStore from "../../store/modules/user";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { getUuid } from "@/utils/common";
export default Vue.extend({
  components: { Editor, Toolbar },
  data() {
    return {
      uploadTokenObj: {},
      token: userStore.state.token,
      editor: null,
      html: "<p></p>",
      currentValue:'',
      // 工具栏配置（注意结构，否则不起作用）
      toolbarConfig: {
        excludeKeys :[
          'todo',
          'codeBlock'
        ]
      },
      // 菜单配置（注意结构，否则不起作用）
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          uploadImage: {
            // 自定义上传图片 方法
            customUpload: this.uploadImg,
            // 上传接口设置文件名
            fieldName: "file",
            meta: {
              token: userStore.state.token,
            },
          },
          uploadVideo: {
            customUpload: this.uploadVideo,
            fieldName: "file",
            meta: {
              token: userStore.state.token,
            },
          },
        },
      },
      mode: "default", // or 'simple'
      // 图片、视频上传服务器地址
      uploadFileUrl: process.env.VUE_APP_BASE_API + "/common/upload",
    };
  },
   props: {
    /* 编辑器的内容 */
    value: {
      type: String,
      default: "",
    },
    /* 高度 */
    height: {
      type: Number,
      default: null,
    },
    /* 最小高度 */
    minHeight: {
      type: Number,
      default: null,
    },
    /* 只读 */
    readOnly: {
      type: Boolean,
      default: false,
    },
    // 上传文件大小限制(MB)
    fileSize: {
      type: Number,
      default: 5,
    },
    /* 类型（base64格式、url格式） */
    type: {
      type: String,
      default: "url",
    },
  },
  watch: {
    // 监听父组件中editor值的变化
    value: {
      handler(val) {
        this.html=val
        this.currentValue=val
      },
      immediate: true,
    },
  },
  mounted() {
    this.initEditor();
  },
  methods: {
    onCreated(editor) {
       // 一定要用 Object.seal() ，否则会报错
      this.editor = Object.seal(editor);
    },
    onChange(editor){
      // this.$emit("change", this.html);
      // this.value=this.html
       this.$emit('input', this.html)
    },
    //自定义上传视频
    uploadVideo(file, insertFn) {
      let imgData = new FormData();
      this.getUploadHost(file).then(() => {
        Object.keys(this.uploadTokenObj).forEach(key => {
          imgData.append(key, this.uploadTokenObj[key]);
        });
        imgData.append("file", file);
        axios({
          url: this.uploadFileUrl,
          data: imgData,
          method: "post",
          // headers: {
          //   // Authorization: "Bearer " + userStore.state.token,
          // },
        }).then((response) => {
          if (response.status === 200) {
            // 插入后端返回的url
            insertFn(this.uploadTokenObj.domain + "/" + this.uploadTokenObj.key);
            this.$message({
              type: "success",
              message: "上传成功",
            });
          } else {
          }
        });
      });
    },
    //自定义上传图片
    uploadImg(file, insertFn) {
      let imgData = new FormData();
      this.getUploadHost(file).then(() => {
        Object.keys(this.uploadTokenObj).forEach(key => {
          imgData.append(key, this.uploadTokenObj[key]);
        });
        imgData.append("file", file);
        axios({
          url: this.uploadFileUrl,
          data: imgData,
          method: "post",
          // headers: {
          //   // Authorization: "Bearer " + userStore.state.token,
          // },
        }).then((response) => {
          if (response.status === 200) {
            // 插入后端返回的url
            insertFn(this.uploadTokenObj.domain + "/" + this.uploadTokenObj.key);
            this.$message({
              type: "success",
              message: "上传成功",
            });
          } else {
          }
        });
      });
    },
    // 获取图片上传地址
    async getUploadHost(file){
      await this.$store.dispatch('base/getUploadToken',{
        businessType: "0",
        businessCode: "000000000"
      }).then(res => {
        this.uploadFileUrl = res.data.host;
        this.editorConfig.MENU_CONF['uploadImage'].server = res.data.host;
        this.uploadTokenObj = {
          url: res.data.host,
          key: `${res.data.dir}/${getUuid(15)}/${file.name}`,
          policy: res.data.policy,
          OSSAccessKeyId: res.data.accessId,
          Signature: res.data.signature,
          success_action_status: 200,
          domain: res.data.domain
        };
      })
    },
  },
  mounted() {
    // 模拟 ajax 请求，异步渲染编辑器
    setTimeout(() => {
      if(this.currentValue){
        this.html=this.currentValue
      }
      // 查看菜单配置项
      // console.log(this.editor.getConfig());
      // const toolbar = DomEditor.getToolbar(this.editor)
      // const curToolbarConfig = toolbar.getConfig()
      // console.log( curToolbarConfig.toolbarKeys ) // 当前菜单排序和分组
    }, 1500);
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
});
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>